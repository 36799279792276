import React from "react"
import { Layout, FlexRow } from "@components/layout"
import { CategoryItem } from "@components/ecommerce"

const renderWithAllProps = (Comp) => (entry, key) => {
  return <Comp {...entry} key={key} />
}

const Categories = ({ pageContext, location }) => {
  const { page, categories, posts } = pageContext

  return (
    <Layout {...page}>
      <h1>Categories</h1>
      <FlexRow>{categories.map(renderWithAllProps(CategoryItem))}</FlexRow>
    </Layout>
  )
}
export default Categories
